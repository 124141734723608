import * as React from "react";
import { cva, VariantProps } from "class-variance-authority";

import { cn } from "@src/ui/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { textVariants } from "@src/ui/text";
import { Loader2 } from "lucide-react";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-lg transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blue-4 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none shadow-sm",
  {
    variants: {
      variant: {
        default: "bg-primary text-background hover:bg-primary-hover",
        destructive:
          "bg-destructive text-background hover:bg-destructive-hover",
        outline:
          "border border-input bg-background hover:bg-gray-1 focus-visible:ring-gray-4 text-foreground disabled:text-muted-foreground disabled:bg-muted",
        secondary: "bg-secondary text-foreground hover:bg-secondary-hover",
        ghost:
          "bg-transparent text-foreground hover:bg-gray-2 focus-visible:ring-blue-4 shadow-none",
        link: "text-foreground underline-offset-4 hover:underline focus-visible:ring-gray-4 shadow-none",
        ai: "bg-gradient-to-r from-[#BD82FA] via-[#FE508F] to-[#FB99A6] text-background hover:opacity-90 focus-visible:ring-purple-4 shadow-none",
      },
      size: {
        default: "h-9 px-4 py-2 gap-1.5",
        xs: "h-6 px-2 gap-1",
        sm: "h-8 px-3 gap-1.5",
        lg: "h-10 px-4 gap-2",
        icon: "h-9 w-9 gap-1",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  dataTestId?: string;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, asChild = false, dataTestId, loading, children, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size }),
          textVariants({
            variant: size === "sm" || size === "xs" ? "P3" : "P2",
          }),
          "font-medium",
          loading ? "opacity-50 cursor-not-allowed" : "",
          className,
        )}
        ref={ref}
        disabled={props.disabled || loading}
        data-testid={dataTestId}
        {...props}
      >
        {loading ? <Loader2 className="size-4 animate-spin" /> : children}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
